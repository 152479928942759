// Refer to https://create-react-app.dev/docs/adding-a-css-modules-stylesheet
// On how to use scss modules with React

// Import WMN Design System scss for use in this scss file
// Tilde (~) represents the root folder(src)
@import '~assets/wmnds/vars';
@import '~assets/wmnds/mixins';

.mapControls {
  position: absolute;
  z-index: 10;
  top: $size-md;
  right: $size-md;

  @media (min-width: $breakpoint-md) {
    top: $size-xsm;
    right: $size-xsm;
  }
}

.mapControlBtn {
  display: flex;
  width: 36px;
  height: 36px;
  border: 0;
  border-radius: 5px;
  background-color: get-color(secondary);
  cursor: pointer;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: $size-xsm;
  }

  svg {
    fill: $white;
  }
}
