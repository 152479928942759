// Refer to https://create-react-app.dev/docs/adding-a-css-modules-stylesheet
// On how to use scss modules with React

// Import WMN Design System scss for use in this scss file
// Tilde (~) represents the root folder(src)
@import '~assets/wmnds/vars';
@import '~assets/wmnds/mixins';

.mainHeading {
  padding-top: $size-lg;
  align-items: flex-start;
  flex-wrap: nowrap;

  @media (min-width: $breakpoint-md) {
    padding-top: 0;
  }

  button {
    display: inline-flex;
    margin-left: $size-sm;
    white-space: nowrap;
  }
}

.backLink {
  display: inline-flex;
  align-items: center;

  svg {
    margin-right: $size-xsm;
    transform: rotate(180deg);
    fill: currentColor;
  }
}

.bgWhite {
  background-color: $white !important;
}
